import React from 'react'
import axios from 'axios'
import Recaptcha from 'react-recaptcha'
import Layout from '../../components/Layout'

class ContactPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            message: '',
            agreement: false,
            captcha: undefined,
            recaptchaInstance: undefined,
            formError: [],
            formSuccess: '',
        }
    }

    componentDidMount() {
        if(this.state.recaptchaInstance) {
            this.state.recaptchaInstance.reset();
        }
    }
    submit = (event) => {
        event.preventDefault()
        this.setState( { formSuccess: '', formError: [] }, this.handleFormEntry )


    }

    handleFormEntry = () => {
        // console.log('SUBMIT')
        // console.log('name: '+this.state.name)
        // console.log('email: '+this.state.email)
        // console.log('message: '+this.state.message)
        // console.log('agreement: '+this.state.agreement)
        // console.log('response: '+this.state.captcha)

        if( this.state.name     === '' ||
            this.state.email    === '' ||
            this.state.address  === '' ||
            this.state.message  === ''
          ) {
            this.setState({ formError: this.state.formError.concat(["Form not filled out completely."]) })
        } else {
            let formData = new FormData() 
            formData.set('name', this.state.name)
            formData.set('email', this.state.email)
            formData.set('message', this.state.message)
            formData.set('address', this.state.address)
            formData.set('website', 'Grand Lakes MUD 4')
            formData.set('response', this.state.captcha)

            axios.post('https://backend.touchstonedistrictservices.com/mailer', formData, 
            //axios.post('http://touchstone-server.test/mailer', formData,
                {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    }
                }).then( function(response) {
                    if(response.data.status === "success") {
                        this.clearForm()
                        this.setState({ formSuccess: "Your message has been sent." })
                    } else {
                        this.setState({ formError: this.state.formError.concat(["Something went wrong, check your entry and try again."]) })
                    }

            }.bind(this) ).catch( function(error) {
                console.log(error.response)
            })

            if(this.state.recaptchaInstance) {
                this.state.recaptchaInstance.reset();
            }

        }

    }

    clearForm = () => {
        this.setState({ name: '', email: '', message: ''})
    }

    changeName = (event) => {
        this.setState({ name: event.target.value })
    }
    changeAddress = (event) => {
        this.setState({ address: event.target.value })
    }
    changeAgreement = (event) => {
        this.setState({ agreement: event.target.checked })
    }
    changeEmail = (event) => {
        this.setState({ email: event.target.value })
    }
    changeMessage = (event) => {
        this.setState({ message: event.target.value })
    }
    captchaVerify = (response) => {
        this.setState({ captcha: response })
    }
    onLoadRecaptcha = () => {
        if(this.state.recaptchaInstance) {
            this.state.recaptchaInstance.reset();
        }
    }

    render() {
        return (
            <Layout>
            <div className="section section--gradient">
            <div className="content-spacer"></div>
            <div className="content container">
            <div className="columns" style={{ margin: '2rem 0 1rem'}}>
                <div className="column is-8 is-offset-1">
                    <h1 className="is-size-1">Contact Us</h1>
                    <p className="">For water and wastewater related emergencies or questions about your bill please contact Municipal District Services (MDS) customer service at (281) 290-6500. For legal inquiries and public information requests contact Schwartz, Page &amp; Harding, L.L.P. at (713) 623-4531. For all other inquiries you may use the form below.</p>
                </div>
            </div>
            <div className="columns" style={{marginBottom: '3rem', padding: '0 1rem'}}>
                <div className="column is-5 is-offset-1">
                    <form name="contact" onSubmit={this.submit}>
                        { this.state.formError.length !== 0 ? (<ul className="notification is-danger">{this.state.formError.map((error) => (
                            <li key={error} >{error}</li>
                        )) }</ul>): < ></ > }
                        { this.state.formSuccess === '' ? ( < ></ > ) : (<div className="notification is-success">{this.state.formSuccess}</div>)}

                        <div className="field">
                            <label htmlFor="name" className="label">Name</label>
                            <div className="control">
                                <input className="input" type="text" name="name" placeholder="Enter your name" onChange={this.changeName} value={this.state.name} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="email" className="label">Email</label>
                            <div className="control">
                                <input className="input" type="email" name="email" placeholder="Enter your email" onChange={this.changeEmail} value={this.state.email} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="address" className="label">Address</label>
                            <div className="control">
                                <input className="input" type="text" name="address" placeholder="Enter your street address" onChange={this.changeAddress} value={this.state.address} />
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="message" className="label">Message</label>
                            <div className="control">
                                <textarea className="textarea" name="message" placeholder="Enter your message" onChange={this.changeMessage} value={this.state.message}></textarea>
                            </div>
                        </div>
                        <Recaptcha render="explicit" ref={e => this.state.recaptchaInstance = e} onloadCallback={this.onLoadRecaptcha} verifyCallback={this.captchaVerify} sitekey="6LdHrGYUAAAAAINbB7Ky8vHL79yhss18gbp_2b-v"/>
                        <div className="field">
                            <div className="control">
                                <input className="button is-primary is-rounded" type="submit" name="submit" value="Submit" />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="column is-3 is-offset-1" style={{marginTop: '3rem'}}>
                    <h2 className="is-size-3">Contact Information</h2>
                    <p><strong>Legal Councel</strong><br />1300 Post Oak Blvd., Ste. 2500,<br />
                    Houston, TX 77056</p>
                    <p>Phone: (713) 623-4531</p>
                    <p>Email: <a href="mailto:info@grandlakesmud4.com">info@grandlakesmud4.com</a></p>

                    <h2 className="is-size-3">Customer Support</h2>
                    <p>For billing and service requests from 8:00am–5pm Monday–Friday</p>
                    <p>Phone: (281) 290-6500<br />
                    Email: <a href="mailto:info@mdswater.com">info@mdswater.com</a></p>
                    <h3 className="is-size-4">After-Hour Emergency</h3>
                    <p>Phone: (281) 290-6500</p>

                </div>
            </div>
            </div>

            </div>
            </Layout>
        )
    }
}

export default ContactPage